:root {
  --color-primary-25: #f5f6ff;
  --color-primary-50: #c7cdf6;
  --color-primary-100: #b6bdf3;
  --color-primary-200: #939dee;
  --color-primary-300: #6f7ee8;
  --color-primary-400: #4c5ee3;
  --color-primary-500: #293edd;
  --color-primary-600: #1f38c6;
  --color-primary-700: #162fb0;
  --color-primary-800: #133372;
  --color-primary-900: #0c2340;
  --color-primary-950: #08182c;

  --color-secondary-25: #fcfcfd;
  --color-secondary-50: #effcff;
  --color-secondary-100: #d5f8ff;
  --color-secondary-200: #b3f1ff;
  --color-secondary-300: #8ee9ff;
  --color-secondary-400: #69e2ff;
  --color-secondary-500: #00cfeb;
  --color-secondary-600: #0399b7;
  --color-secondary-700: #0a7a94;
  --color-secondary-800: #126278;
  --color-secondary-900: #145165;
  --color-secondary-950: #063646;

  --color-gray-light-25: #f6f7f8;
  --color-gray-light-50: #edeff2;
  --color-gray-light-100: #dee1eb;
  --color-gray-light-200: #c0c6d6;
  --color-gray-light-300: #a3aac2;
  --color-gray-light-400: #78819b;
  --color-gray-light-500: #606b8a;
  --color-gray-light-600: #545d78;
  --color-gray-light-700: #454d63;
  --color-gray-light-800: #363c4e;
  --color-gray-light-900: #121926;
  --color-gray-light-950: #0f1623;
  --color-gray-light-400-hsl: 225 15% 54%;
  --color-gray-light-500-hsl: 224.29 17.95% 45.88%;

  --color-gray-dark-25: #fafafa;
  --color-gray-dark-50: #f7f7f7;
  --color-gray-dark-100: #f0f0f1;
  --color-gray-dark-200: #ececed;
  --color-gray-dark-300: #cecfd2;
  --color-gray-dark-400: #94979c;
  --color-gray-dark-500: #85888e;
  --color-gray-dark-600: #61656c;
  --color-gray-dark-700: #373a41;
  --color-gray-dark-800: #22262f;
  --color-gray-dark-900: #13161b;
  --color-gray-dark-950: #0c0e12;
  --color-gray-dark-400-hsl: 218 4% 60%;
  --color-gray-dark-500-hsl: 220 4% 54%;

  --color-success-25: #f4faf7;
  --color-success-50: #dff1e8;
  --color-success-100: #b3dfc9;
  --color-success-200: #80cba6;
  --color-success-300: #4eb782;
  --color-success-400: #26a666;
  --color-success-500: #00944a;
  --color-success-600: #008a45;
  --color-success-700: #007a3d;
  --color-success-800: #006b36;
  --color-success-900: #004d26;

  --color-warning-25: #fff7f0;
  --color-warning-50: #ffefe0;
  --color-warning-100: #ffd7b2;
  --color-warning-200: #ffbd80;
  --color-warning-300: #ffa34d;
  --color-warning-400: #ff8e24;
  --color-warning-500: #ff7b00;
  --color-warning-600: #fa7900;
  --color-warning-700: #f57600;
  --color-warning-800: #f07400;
  --color-warning-900: #e56f00;

  --color-error-25: #fff5f6;
  --color-error-50: #ffebee;
  --color-error-100: #ffccd4;
  --color-error-200: #ef9aa8;
  --color-error-300: #e57184;
  --color-error-400: #ef526c;
  --color-error-500: #f43454;
  --color-error-600: #e53451;
  --color-error-700: #d3314c;
  --color-error-800: #c72943;
  --color-error-900: #b51c36;

  --color-foreground-brand-primary: var(--color-primary-500);

  --box-shadow-elevation-00: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-elevation-01: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  --box-shadow-elevation-02: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  --box-shadow-elevation-03: 0 12px 24px 0 rgba(0, 0, 0, 0.12);

  --button-label-weight: 500;
  --button-background-primary-outline_hover: var(--color-secondary-50);
  --button-border-radius: theme(borderRadius.full);
  --input-box-shadow: 0 0 #0000;

  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --sans-font: var(--font-volte);
  --sans-serif-font: var(--font-volte);

  /* ============================================= LEGACY COLORS ===================================================== */
  /* DON'T USE these legacy colours as we will remove these eventually! */

  /* persian blue */
  --color-primary-bold-25: #f5f6ff;
  --color-primary-bold-50: #c7cdf6;
  --color-primary-bold-100: #b6bdf3;
  --color-primary-bold-200: #939dee;
  --color-primary-bold-300: #6f7ee8;
  --color-primary-bold-400: #4c5ee3;
  --color-primary-bold-500: #293edd;
  --color-primary-bold-600: #1f38c6;
  --color-primary-bold-700: #162fb0;
  --color-primary-bold-800: #133372;
  --color-primary-bold-900: #0c2340;
  --color-primary-bold-950: #08182c;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* mabel */
  --color-primary-light-100: #f5fdff;
  --color-primary-light-400: #dbf9ff;
  --color-primary-light-500: 190 100% 91.76%;
  --color-primary-light-600: #75e8ff;
  --color-primary-light-700: #1ad9ff;
  --color-primary-light-800: #009dbd;
  --color-primary-light-900: #004d5c;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* egg blue */
  --color-primary-bright-50: #e5fcff;
  --color-primary-bright-100: #c7f8ff;
  --color-primary-bright-200: #8ff2ff;
  --color-primary-bright-300: #5cecff;
  --color-primary-bright-400: #24e5ff;
  --color-primary-bright-500: #00cfeb;
  --color-primary-bright-600: #00a7bd;
  --color-primary-bright-700: #007e8f;
  --color-primary-bright-800: #00515c;
  --color-primary-bright-900: #00292e;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* blue zodiac */
  --color-primary-deep-50: #d8e6f8;
  --color-primary-deep-100: #b6d1f1;
  --color-primary-deep-200: #6da2e4;
  --color-primary-deep-300: #2774d2;
  --color-primary-deep-400: #1a4c89;
  --color-primary-deep-500: #0c2340;
  --color-primary-deep-600: #0a1d34;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* pink lace */
  --color-secondary-pink-400: #ffd1f6;
  --color-secondary-pink-500: #ffc7f4;
  --color-secondary-pink-600: #ff6be1;
  --color-secondary-pink-700: #ff0fcf;
  --color-secondary-pink-800: #b80093;
  --color-secondary-pink-900: #5c0049;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* brink pink */
  --color-secondary-salmon-100: #fee1e6;
  --color-secondary-salmon-200: #fdbeca;
  --color-secondary-salmon-300: #fda1b1;
  --color-secondary-salmon-400: #fc8399;
  --color-secondary-salmon-500: #fb637e;
  --color-secondary-salmon-600: #f91f47;
  --color-secondary-salmon-700: #cc052a;
  --color-secondary-salmon-800: #86031b;
  --color-secondary-salmon-900: #46020e;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* burnt ember */
  --color-secondary-maroon-50: #f8e8e8;
  --color-secondary-maroon-100: #f0cccd;
  --color-secondary-maroon-200: #e0999b;
  --color-secondary-maroon-300: #d16768;
  --color-secondary-maroon-400: #bc393b;
  --color-secondary-maroon-500: #8a2a2b;
  --color-secondary-maroon-600: #6d2123;
  --color-secondary-maroon-700: #52191a;
  --color-secondary-maroon-800: #371111;

  /* mountain meadow */
  --color-tertiary-apple-50: #e8fcf1;
  --color-tertiary-apple-100: #d2f9e4;
  --color-tertiary-apple-200: #a5f3c8;
  --color-tertiary-apple-300: #78edad;
  --color-tertiary-apple-400: #4fe894;
  --color-tertiary-apple-500: #21e278;
  --color-tertiary-apple-600: #18b961;
  --color-tertiary-apple-700: #128747;
  --color-tertiary-apple-800: #0c5a2f;
  --color-tertiary-apple-900: #062d18;

  --color-info-50: var(--color-primary-bold-50);
  --color-info-100: var(--color-primary-bold-100);
  --color-info-200: var(--color-primary-bold-200);
  --color-info-300: var(--color-primary-bold-300);
  --color-info-400: var(--color-primary-bold-400);
  --color-info-500: var(--color-primary-bold-500);
  --color-info-600: var(--color-primary-bold-600);
  --color-info-700: var(--color-primary-bold-700);
  --color-info-800: var(--color-primary-bold-800);
  --color-info-900: var(--color-primary-bold-900);
  /* ============================================= LEGACY COLORS ===================================================== */
}

.kijiji {
  --color-primary-50: #f3f3f9;
  --color-primary-100: #cacaea;
  --color-primary-200: #918fba;
  --color-primary-300: #696696;
  --color-primary-400: #4b4881;
  --color-primary-500: #373373;
  --color-primary-600: #23234f;
  --color-primary-700: #171935;
  --color-primary-800: #0b0c1f;

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  /* purple */
  --color-primary-bold-50: #f3f3f9;
  --color-primary-bold-100: #cacaea;
  --color-primary-bold-200: #918fba;
  --color-primary-bold-300: #696696;
  --color-primary-bold-400: #4b4881;
  --color-primary-bold-500: #373373;
  --color-primary-bold-600: #23234f;
  --color-primary-bold-700: #171935;
  --color-primary-bold-800: #0b0c1f;

  /* violet */
  --color-violet-50: #f6edf8;
  --color-violet-100: #ebd7ef;
  --color-violet-200: #d9b2e1;
  --color-violet-300: #c58ad1;
  --color-violet-400: #b365c3;
  --color-violet-500: #9b44ad;
  --color-violet-600: #7d378b;
  --color-violet-700: #5c2867;
  --color-violet-800: #3f1b46;
  --color-violet-900: #1e0d21;

  --color-gray-light-100: #f8f9f9;
  --color-gray-light-200: #ececee;
  --color-gray-light-300: #d2d3d7;
  --color-gray-light-400: #8e909b;
  --color-gray-light-500: #6f727f;
  --color-gray-light-600: #3e4153;
  --color-gray-light-700: #343746;
  --color-gray-light-400-hsl: 230.77 6.1% 58.24%;
  --color-gray-light-500-hsl: 228.75, 6.72%, 46.67%;

  --color-success-50: #f4faf5;
  --color-success-100: #daefdf;
  --color-success-200: #bae5c6;
  --color-success-300: #90d3a6;
  --color-success-400: #56c17c;
  --color-success-500: #37a864;
  --color-success-600: #2d844c;
  --color-success-700: #215934;
  --color-success-800: #11351c;

  --color-info-50: #f0fafc;
  --color-info-100: #cbeaf4;
  --color-info-200: #9cd4f2;
  --color-info-300: #69b8ed;
  --color-info-400: #3b97e2;
  --color-info-500: #2681db;
  --color-info-600: #155e9b;
  --color-info-700: #103a56;
  --color-info-800: #08283a;

  --color-warning-50: #fffbf2;
  --color-warning-100: #feeed1;
  --color-warning-200: #f9db91;
  --color-warning-300: #ffd255;
  --color-warning-400: #ffb92e;
  --color-warning-500: #f8aa17;
  --color-warning-600: #d49129;
  --color-warning-700: #aa6f29;
  --color-warning-800: #845726;

  --color-error-50: #fef4f5;
  --color-error-100: #fcdadc;
  --color-error-200: #fabec2;
  --color-error-300: #ff8383;
  --color-error-400: #f96262;
  --color-error-500: #f1454f;
  --color-error-600: #bb3c4d;
  --color-error-700: #993244;
  --color-error-800: #721f31;

  --color-foreground-brand-primary: var(--color-primary-500);

  /* TODO */
  --color-primary-bold-50: var(--color-primary-50);
  --color-primary-bold-100: var(--color-primary-100);
  --color-primary-bold-200: var(--color-primary-200);
  --color-primary-bold-300: var(--color-primary-300);
  --color-primary-bold-400: var(--color-primary-400);
  --color-primary-bold-500: var(--color-primary-500);
  --color-primary-bold-600: var(--color-primary-600);
  --color-primary-bold-700: var(--color-primary-700);
  --color-primary-bold-800: var(--color-primary-800);
  --color-primary-bold-900: var(--color-primary-900);

  --box-shadow-elevation-00: 0 1px 2px 0 rgba(62, 65, 83, 0.1);
  --box-shadow-elevation-01: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-elevation-02: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-elevation-03: 2px 2px 4px 0 rgba(62, 65, 83, 0.1);

  --button-background-primary-outline_hover: #f1f3f9;
  --button-border-radius: theme(borderRadius.DEFAULT);
  --input-box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.04);

  --sans-font: var(--font-larsseit);
}

.autocan {
  --color-primary-50: #f4f6fb;
  --color-primary-100: #e8ebf6;
  --color-primary-200: #cdd7ea;
  --color-primary-300: #a0b3d9;
  --color-primary-400: #6e8dc2;
  --color-primary-500: #4b6eac;
  --color-primary-600: #395690;
  --color-primary-700: #2f4575;
  --color-primary-800: #2a3c62;
  --color-primary-900: #273553;
  --color-primary-950: #0a0d15;

  --color-foreground-brand-primary: var(--color-primary-500);

  /* TODO: DON'T USE. This legacy colours and we want to remove this eventually! */
  --color-primary-bold-50: #f4f6fb;
  --color-primary-bold-100: #e8ebf6;
  --color-primary-bold-200: #cdd7ea;
  --color-primary-bold-300: #a0b3d9;
  --color-primary-bold-400: #6e8dc2;
  --color-primary-bold-500: #4b6eac;
  --color-primary-bold-600: #395690;
  --color-primary-bold-700: #2f4575;
  --color-primary-bold-800: #2a3c62;
  --color-primary-bold-900: #273553;
  --color-primary-bold-950: #0a0d15;

  --color-gray-light-25: #fdfdfd;
  --color-gray-light-50: #fafafa;
  --color-gray-light-100: #f5f5f5;
  --color-gray-light-200: #e9eaeb;
  --color-gray-light-300: #d5d7da;
  --color-gray-light-400: #a4a7ae;
  --color-gray-light-500: #717680;
  --color-gray-light-600: #535862;
  --color-gray-light-700: #414651;
  --color-gray-light-800: #252b37;
  --color-gray-light-900: #181d27;
  --color-gray-light-950: #0a0d12;
  --color-gray-light-400-hsl: 215 13% 65%;
  --color-gray-light-500-hsl: 215 12% 47%;

  --color-success-50: #f4faf5;
  --color-success-100: #daefdf;
  --color-success-200: #bae5c6;
  --color-success-300: #90d3a6;
  --color-success-400: #56c17c;
  --color-success-500: #37a864;
  --color-success-600: #2d844c;
  --color-success-700: #215934;
  --color-success-800: #11351c;

  --color-info-50: #f0fafc;
  --color-info-100: #cbeaf4;
  --color-info-200: #9cd4f2;
  --color-info-300: #69b8ed;
  --color-info-400: #3b97e2;
  --color-info-500: #2681db;
  --color-info-600: #155e9b;
  --color-info-700: #103a56;
  --color-info-800: #08283a;

  --color-warning-50: #fffbf2;
  --color-warning-100: #feeed1;
  --color-warning-200: #f9db91;
  --color-warning-300: #ffd255;
  --color-warning-400: #ffb92e;
  --color-warning-500: #f8aa17;
  --color-warning-600: #d49129;
  --color-warning-700: #aa6f29;
  --color-warning-800: #845726;

  --color-error-50: #fef4f5;
  --color-error-100: #fcdadc;
  --color-error-200: #fabec2;
  --color-error-300: #ff8383;
  --color-error-400: #f96262;
  --color-error-500: #f1454f;
  --color-error-600: #bb3c4d;
  --color-error-700: #993244;
  --color-error-800: #721f31;

  --box-shadow-elevation-00: 0 1px 2px 0 rgba(62, 65, 83, 0.1);
  --box-shadow-elevation-01: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-elevation-02: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-elevation-03: 2px 2px 4px 0 rgba(62, 65, 83, 0.1);

  --button-background-primary-outline_hover: #f1f3f9;
  --button-border-radius: theme(borderRadius.full);
  --input-box-shadow: 0 0 #0000;
}

.roadwise {
  --color-primary-25: #fdf3f3;
  --color-primary-50: #f9f5ff;
  --color-primary-100: #fce4e5;
  --color-primary-200: #fbcdcf;
  --color-primary-300: #f7aaad;
  --color-primary-400: #f0797e;
  --color-primary-500: #e54e55;
  --color-primary-600: #d13138;
  --color-primary-700: #b0252b;
  --color-primary-800: #912328;
  --color-primary-900: #792327;
  --color-primary-950: #410e10;

  --color-secondary-25: #fcfcfd;
  --color-secondary-50: #f8f9fc;
  --color-secondary-100: #eaecf5;
  --color-secondary-200: #d5d9eb;
  --color-secondary-300: #b3b8db;
  --color-secondary-400: #717bbc;
  --color-secondary-500: #4e5ba6;
  --color-secondary-600: #3e4784;
  --color-secondary-700: #363f72;
  --color-secondary-800: #293056;
  --color-secondary-900: #101323;
  --color-secondary-950: #0d0f1c;

  --color-gray-light-25: #fcfcfd;
  --color-gray-light-50: #f8fafc;
  --color-gray-light-100: #eef2f6;
  --color-gray-light-200: #e3e8ef;
  --color-gray-light-300: #cdd5df;
  --color-gray-light-400: #9aa4b2;
  --color-gray-light-500: #697586;
  --color-gray-light-600: #4b5565;
  --color-gray-light-700: #364152;
  --color-gray-light-800: #202939;
  --color-gray-light-900: #121926;
  --color-gray-light-950: #0d121c;
  --color-gray-light-400-hsl: 215 13% 65%;
  --color-gray-light-500-hsl: 215 12% 47%;

  --color-gray-dark-25: #fafafa;
  --color-gray-dark-50: #f7f7f7;
  --color-gray-dark-100: #f0f0f1;
  --color-gray-dark-200: #ececed;
  --color-gray-dark-300: #cecfd2;
  --color-gray-dark-400: #94979c;
  --color-gray-dark-500: #85888e;
  --color-gray-dark-600: #61656c;
  --color-gray-dark-700: #373a41;
  --color-gray-dark-800: #22262f;
  --color-gray-dark-900: #13161b;
  --color-gray-dark-950: #0c0e12;
  --color-gray-dark-400-hsl: 218 4% 60%;
  --color-gray-dark-500-hsl: 220 4% 54%;

  --color-success-25: #f6fef9;
  --color-success-50: #ecfdf3;
  --color-success-100: #dcfae6;
  --color-success-200: #abefc6;
  --color-success-300: #75e0a7;
  --color-success-400: #47cd89;
  --color-success-500: #17b26a;
  --color-success-600: #079455;
  --color-success-700: #067647;
  --color-success-800: #085d3a;
  --color-success-900: #074d31;
  --color-success-950: #053321;

  --color-warning-25: #fffcf5;
  --color-warning-50: #fffaeb;
  --color-warning-100: #fef0c7;
  --color-warning-200: #fedf89;
  --color-warning-300: #fec84b;
  --color-warning-400: #fdb022;
  --color-warning-500: #f79009;
  --color-warning-600: #dc6803;
  --color-warning-700: #b54708;
  --color-warning-800: #93370d;
  --color-warning-900: #7a2e0e;
  --color-warning-950: #4e1d09;

  --color-error-25: #fffbfa;
  --color-error-50: #fef3f2;
  --color-error-100: #fee4e2;
  --color-error-200: #fecdca;
  --color-error-300: #fda29b;
  --color-error-400: #f97066;
  --color-error-500: #f04438;
  --color-error-600: #d92d20;
  --color-error-700: #b42318;
  --color-error-800: #912018;
  --color-error-900: #7a271a;
  --color-error-950: #55160c;

  --color-foreground-brand-primary: var(--color-secondary-600);

  --box-shadow-elevation-00: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-elevation-01: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  --box-shadow-elevation-02: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  --box-shadow-elevation-03: 0 12px 24px 0 rgba(0, 0, 0, 0.12);

  /* TODO EVALUATE IF USEFUL */
  --radius-lg: 10px;

  --button-label-weight: 400;
  --button-background-primary-outline_hover: var(--color-primary-25);
  --button-border-radius: var(--radius-lg);
  --input-box-shadow: 0 0 #0000;

  --sans-font: var(--font-mulish);
  --sans-serif-font: var(--font-mulish);

  /* ============================================= LEGACY COLORS ===================================================== */
  /* DON'T USE these legacy colours as we will remove these eventually! */

  --color-primary-bold-50: var(--color-primary-50);
  --color-primary-bold-100: var(--color-primary-100);
  --color-primary-bold-200: var(--color-primary-200);
  --color-primary-bold-300: var(--color-primary-300);
  --color-primary-bold-400: var(--color-primary-400);
  --color-primary-bold-500: var(--color-primary-500);
  --color-primary-bold-600: var(--color-primary-600);
  --color-primary-bold-700: var(--color-primary-700);
  --color-primary-bold-800: var(--color-primary-800);
  --color-primary-bold-900: var(--color-primary-900);

  /* TODO */
  /* mabel */
  --color-primary-light-100: #f5fdff;
  --color-primary-light-400: #dbf9ff;
  --color-primary-light-500: 190 100% 91.76%;
  --color-primary-light-600: #75e8ff;
  --color-primary-light-700: #1ad9ff;
  --color-primary-light-800: #009dbd;
  --color-primary-light-900: #004d5c;

  /* TODO */
  /* egg blue */
  --color-primary-bright-50: #e5fcff;
  --color-primary-bright-100: #c7f8ff;
  --color-primary-bright-200: #8ff2ff;
  --color-primary-bright-300: #5cecff;
  --color-primary-bright-400: #24e5ff;
  --color-primary-bright-500: #00cfeb;
  --color-primary-bright-600: #00a7bd;
  --color-primary-bright-700: #007e8f;
  --color-primary-bright-800: #00515c;
  --color-primary-bright-900: #00292e;

  /* TODO */
  /* blue zodiac */
  --color-primary-deep-50: #d8e6f8;
  --color-primary-deep-100: #b6d1f1;
  --color-primary-deep-200: #6da2e4;
  --color-primary-deep-300: #2774d2;
  --color-primary-deep-400: #1a4c89;
  --color-primary-deep-500: #0c2340;
  --color-primary-deep-600: #0a1d34;

  /* TODO */
  /* pink lace */
  --color-secondary-pink-400: #ffd1f6;
  --color-secondary-pink-500: #ffc7f4;
  --color-secondary-pink-600: #ff6be1;
  --color-secondary-pink-700: #ff0fcf;
  --color-secondary-pink-800: #b80093;
  --color-secondary-pink-900: #5c0049;

  /* TODO */
  /* brink pink */
  --color-secondary-salmon-100: #fee1e6;
  --color-secondary-salmon-200: #fdbeca;
  --color-secondary-salmon-300: #fda1b1;
  --color-secondary-salmon-400: #fc8399;
  --color-secondary-salmon-500: #fb637e;
  --color-secondary-salmon-600: #f91f47;
  --color-secondary-salmon-700: #cc052a;
  --color-secondary-salmon-800: #86031b;
  --color-secondary-salmon-900: #46020e;

  /* TODO */
  /* burnt ember */
  --color-secondary-maroon-50: #f8e8e8;
  --color-secondary-maroon-100: #f0cccd;
  --color-secondary-maroon-200: #e0999b;
  --color-secondary-maroon-300: #d16768;
  --color-secondary-maroon-400: #bc393b;
  --color-secondary-maroon-500: #8a2a2b;
  --color-secondary-maroon-600: #6d2123;
  --color-secondary-maroon-700: #52191a;
  --color-secondary-maroon-800: #371111;

  /* TODO */
  /* mountain meadow */
  --color-tertiary-apple-50: #e8fcf1;
  --color-tertiary-apple-100: #d2f9e4;
  --color-tertiary-apple-200: #a5f3c8;
  --color-tertiary-apple-300: #78edad;
  --color-tertiary-apple-400: #4fe894;
  --color-tertiary-apple-500: #21e278;
  --color-tertiary-apple-600: #18b961;
  --color-tertiary-apple-700: #128747;
  --color-tertiary-apple-800: #0c5a2f;
  --color-tertiary-apple-900: #062d18;

  --color-info-50: var(--color-primary-bold-50);
  --color-info-100: var(--color-primary-bold-100);
  --color-info-200: var(--color-primary-bold-200);
  --color-info-300: var(--color-primary-bold-300);
  --color-info-400: var(--color-primary-bold-400);
  --color-info-500: var(--color-primary-bold-500);
  --color-info-600: var(--color-primary-bold-600);
  --color-info-700: var(--color-primary-bold-700);
  --color-info-800: var(--color-primary-bold-800);
  --color-info-900: var(--color-primary-bold-900);

  /* ============================================= LEGACY COLORS ===================================================== */
}

.throttle {
  --color-primary-25: #f9fffb;
  --color-primary-50: #effaf3;
  --color-primary-100: #d6f9e3;
  --color-primary-200: #bdeace;
  --color-primary-300: #91d9b1;
  --color-primary-400: #65c392;
  --color-primary-500: #2ead6f;
  --color-primary-600: #1e8f5b;
  --color-primary-700: #19754d;
  --color-primary-800: #175e40;
  --color-primary-900: #144d35;
  --color-primary-950: #0a2e20;

  --color-secondary-25: #fcfcfd;
  --color-secondary-50: #f8f9fc;
  --color-secondary-100: #eaecf5;
  --color-secondary-200: #d5d9eb;
  --color-secondary-300: #b3b8db;
  --color-secondary-400: #717bbc;
  --color-secondary-500: #4e5ba6;
  --color-secondary-600: #3e4784;
  --color-secondary-700: #363f72;
  --color-secondary-800: #293056;
  --color-secondary-900: #101323;
  --color-secondary-950: #0d0f1c;

  --color-gray-light-25: #fdfdfd;
  --color-gray-light-50: #fafafa;
  --color-gray-light-100: #f5f5f5;
  --color-gray-light-200: #e9eaeb;
  --color-gray-light-300: #d5d7da;
  --color-gray-light-400: #a4a7ae;
  --color-gray-light-500: #717680;
  --color-gray-light-600: #535862;
  --color-gray-light-700: #414651;
  --color-gray-light-800: #252b37;
  --color-gray-light-900: #181d27;
  --color-gray-light-950: #0a0d12;
  --color-gray-light-400-hsl: 215 13% 65%;
  --color-gray-light-500-hsl: 215 12% 47%;

  --color-gray-dark-25: #fafafa;
  --color-gray-dark-50: #f7f7f7;
  --color-gray-dark-100: #f0f0f1;
  --color-gray-dark-200: #ececed;
  --color-gray-dark-300: #cecfd2;
  --color-gray-dark-400: #94979c;
  --color-gray-dark-500: #85888e;
  --color-gray-dark-600: #61656c;
  --color-gray-dark-700: #373a41;
  --color-gray-dark-800: #22262f;
  --color-gray-dark-900: #13161b;
  --color-gray-dark-950: #0c0e12;
  --color-gray-dark-400-hsl: 218 4% 60%;
  --color-gray-dark-500-hsl: 220 4% 54%;
  --color-gray-dark-950-hsl: 218, 17%, 9%;

  --color-warning-25: #fffcf5;
  --color-warning-50: #fffaeb;
  --color-warning-100: #fef0c7;
  --color-warning-200: #fedf89;
  --color-warning-300: #fec84b;
  --color-warning-400: #fdb022;
  --color-warning-500: #f79009;
  --color-warning-600: #dc6803;
  --color-warning-700: #b54708;
  --color-warning-800: #93370d;
  --color-warning-900: #7a2e0e;
  --color-warning-950: #4e1d09;

  --color-error-25: #fffbfa;
  --color-error-50: #fef3f2;
  --color-error-100: #fee4e2;
  --color-error-200: #fecdca;
  --color-error-300: #fda29b;
  --color-error-400: #f97066;
  --color-error-500: #f04438;
  --color-error-600: #d92d20;
  --color-error-700: #b42318;
  --color-error-800: #912018;
  --color-error-900: #7a271a;
  --color-error-950: #55160c;

  --color-success-25: #f6fef9;
  --color-success-50: #ecfdf3;
  --color-success-100: #dcfae6;
  --color-success-200: #abefc6;
  --color-success-300: #75e0a7;
  --color-success-400: #47cd89;
  --color-success-500: #17b26a;
  --color-success-600: #079455;
  --color-success-700: #067647;
  --color-success-800: #085d3a;
  --color-success-900: #074d31;
  --color-success-950: #053321;

  --color-foreground-brand-primary: var(--color-primary-600);

  --box-shadow-elevation-00: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  --box-shadow-elevation-01: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  --box-shadow-elevation-02: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  --box-shadow-elevation-03: 0 12px 24px 0 rgba(0, 0, 0, 0.12);

  --button-label-weight: 400;
  --button-background-primary-outline_hover: var(--color-primary-50);
  --button-border-radius: var(borderRadius.full);
  --input-box-shadow: 0 0 #0000;

  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 300;
  --font-weight-medium: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 700;

  --sans-font: var(--font-brother-1816);
  --sans-serif-font: var(--font-brother-1816);

  /* ============================================= LEGACY COLORS ===================================================== */
  /* DON'T USE these legacy colours as we will remove these eventually! */

  /* TODO: Not part of Design System  */
  --color-primary-bold-50: var(--color-primary-50);
  --color-primary-bold-100: var(--color-primary-100);
  --color-primary-bold-200: var(--color-primary-200);
  --color-primary-bold-300: var(--color-primary-300);
  --color-primary-bold-400: var(--color-primary-400);
  --color-primary-bold-500: var(--color-primary-500);
  --color-primary-bold-600: var(--color-primary-600);
  --color-primary-bold-700: var(--color-primary-700);
  --color-primary-bold-800: var(--color-primary-800);
  --color-primary-bold-900: var(--color-primary-900);

  /* TODO: Not part of Design System */
  /* mabel */
  --color-primary-light-100: #f5fdff;
  --color-primary-light-400: #dbf9ff;
  --color-primary-light-500: 190 100% 91.76%;
  --color-primary-light-600: #75e8ff;
  --color-primary-light-700: #1ad9ff;
  --color-primary-light-800: #009dbd;
  --color-primary-light-900: #004d5c;

  /* TODO : Not part of Design System*/
  /* egg blue */
  --color-primary-bright-50: #e5fcff;
  --color-primary-bright-100: #c7f8ff;
  --color-primary-bright-200: #8ff2ff;
  --color-primary-bright-300: #5cecff;
  --color-primary-bright-400: #24e5ff;
  --color-primary-bright-500: #00cfeb;
  --color-primary-bright-600: #00a7bd;
  --color-primary-bright-700: #007e8f;
  --color-primary-bright-800: #00515c;
  --color-primary-bright-900: #00292e;

  /* TODO: Not part of Design System */
  /* blue zodiac */
  --color-primary-deep-50: #d8e6f8;
  --color-primary-deep-100: #b6d1f1;
  --color-primary-deep-200: #6da2e4;
  --color-primary-deep-300: #2774d2;
  --color-primary-deep-400: #1a4c89;
  --color-primary-deep-500: #0c2340;
  --color-primary-deep-600: #0a1d34;

  /* TODO: Not part of Design System */
  /* pink lace */
  --color-secondary-pink-400: #ffd1f6;
  --color-secondary-pink-500: #ffc7f4;
  --color-secondary-pink-600: #ff6be1;
  --color-secondary-pink-700: #ff0fcf;
  --color-secondary-pink-800: #b80093;
  --color-secondary-pink-900: #5c0049;

  /* TODO: Not part of Design System */
  /* brink pink */
  --color-secondary-salmon-100: #fee1e6;
  --color-secondary-salmon-200: #fdbeca;
  --color-secondary-salmon-300: #fda1b1;
  --color-secondary-salmon-400: #fc8399;
  --color-secondary-salmon-500: #fb637e;
  --color-secondary-salmon-600: #f91f47;
  --color-secondary-salmon-700: #cc052a;
  --color-secondary-salmon-800: #86031b;
  --color-secondary-salmon-900: #46020e;

  /* TODO: Not part of Design System */
  /* burnt ember */
  --color-secondary-maroon-50: #f8e8e8;
  --color-secondary-maroon-100: #f0cccd;
  --color-secondary-maroon-200: #e0999b;
  --color-secondary-maroon-300: #d16768;
  --color-secondary-maroon-400: #bc393b;
  --color-secondary-maroon-500: #8a2a2b;
  --color-secondary-maroon-600: #6d2123;
  --color-secondary-maroon-700: #52191a;
  --color-secondary-maroon-800: #371111;

  /* TODO: Not part of Design System */
  /* mountain meadow */
  --color-tertiary-apple-50: #e8fcf1;
  --color-tertiary-apple-100: #d2f9e4;
  --color-tertiary-apple-200: #a5f3c8;
  --color-tertiary-apple-300: #78edad;
  --color-tertiary-apple-400: #4fe894;
  --color-tertiary-apple-500: #21e278;
  --color-tertiary-apple-600: #18b961;
  --color-tertiary-apple-700: #128747;
  --color-tertiary-apple-800: #0c5a2f;
  --color-tertiary-apple-900: #062d18;

  /* TODO: Not part of Design System */
  --color-info-50: var(--color-primary-bold-50);
  --color-info-100: var(--color-primary-bold-100);
  --color-info-200: var(--color-primary-bold-200);
  --color-info-300: var(--color-primary-bold-300);
  --color-info-400: var(--color-primary-bold-400);
  --color-info-500: var(--color-primary-bold-500);
  --color-info-600: var(--color-primary-bold-600);
  --color-info-700: var(--color-primary-bold-700);
  --color-info-800: var(--color-primary-bold-800);
  --color-info-900: var(--color-primary-bold-900);

  /* ============================================= LEGACY COLORS ===================================================== */
}
