@media (max-width: 1280px) {
    .warranty-complete-overlay {
        background-image: linear-gradient(
          to bottom,
          #D5F8FF 0%,
          #F6F7F8 65%
        );
      }
}

@media (min-width: 1280px) {
    .warranty-complete-overlay {
        background-image: linear-gradient(
          to bottom,
          #D5F8FF 0%,
          #D5F8FF 45%,
          #F6F7F8 45%,
          #F6F7F8 100%
        );
      }
}
