.date-picker-wrapper {
  @apply !shadow-elevation-03 !border-gray-50;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  @apply !border-b-white;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  @apply !border-b-gray-50;
}

.date-picker-wrapper .react-datepicker__header {
  @apply bg-white border-b-white;
}

.date-picker-wrapper .react-datepicker__month-container {
  @apply font-sans;
}

.date-picker-wrapper .react-datepicker__week {
  @apply flex justify-around;
}

.date-picker-wrapper .react-datepicker__day {
  @apply hover:bg-primary-light hover:rounded-full;
}

.date-picker-wrapper .react-datepicker__day-names {
  @apply flex justify-around text-gray-500 font-medium text-center text-xs;
}

.date-picker-wrapper .react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full text-text-light-100;
  @apply text-sm leading-4 font-normal; /* Referenced from "caption-2" custom class */
}

.date-picker-wrapper .react-datepicker__day--selected {
  @apply bg-primary-bold text-white font-semibold rounded-full;
}

.date-picker-wrapper
  .react-datepicker__day--selected:is(.react-datepicker__day--today) {
  @apply text-white hover:text-primary-bold;
}

.date-picker-wrapper .react-datepicker__day--today {
  @apply text-primary-bold;
}

.date-picker-wrapper .react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.date-picker-wrapper .react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__month-text {
  @apply hover:bg-primary-light hover:rounded-full;
}

.react-datepicker__month--selected {
  @apply bg-primary-bold text-white font-semibold rounded-full;
}

.date-picker-wrapper .react-datepicker__input-time-container {
  @apply font-sans;
}

.date-picker-wrapper .react-datepicker-time__input {
  @apply text-sm rounded-lg h-8 border-gray-50 cursor-pointer;
}

.custom-date-input {
  @apply inline-flex w-52 px-3 py-2 border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0;
  @layer body-4;
}

.custom-date-header {
  @apply flex justify-center bg-white;
  @layer m-.5;
}

.custom-date-header-select {
  @apply border-gray-50 my-1 h-9 cursor-pointer;
  @layer body-3;
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}
