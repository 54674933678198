/* we want this to be more specific to override the default button styling */
.toggle-buttons-group {
  @apply flex;

  .toggle-button {
    @apply rounded-none flex-1 shadow-none border-solid border border-gray-100;
  }

  .toggle-button:first-child {
    @apply border-l-[1px] rounded-l-lg;
  }

  .toggle-button:not(:first-child):not(:last-child) {
    @apply border-l-0;
  }

  .toggle-button:last-child {
    @apply rounded-r-lg;
  }

  .toggle-button-selected {
    @apply bg-primary-500 border-primary-500 disabled:text-white;
  }
}
