@media (max-width: 1280px) {
  .consumer-overlay {
    background-image: linear-gradient(
      to bottom,
      #dfe2fa 0%,
      #dfe2fa 260px,
      #f6f7f8 260px,
      #f6f7f8 100%
    );
  }
}

@media (min-width: 768px) {
  .consumer-overlay {
    background-image: linear-gradient(
      to bottom,
      #dfe2fa 0%,
      #dfe2fa 410px,
      #f6f7f8 410px,
      #f6f7f8 100%
    );
  }
}

@media (min-width: 1280px) {
  .consumer-overlay {
    background-image: linear-gradient(
      to bottom,
      #dfe2fa 0%,
      #dfe2fa 450px,
      #f6f7f8 450px,
      #f6f7f8 100%
    );
  }
}
