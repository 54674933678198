.ae-balloon-launcher .ae-balloon {
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  color: #0c2340 !important;
}
.ae-balloon-launcher .ae-balloon .ae-col-model > img {
  width: 75px;
  height: 100px;
}
.ae-balloon-launcher .ae-balloon::after {
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
}
.ae-balloon-launcher .ae-balloon-close {
  background-color: #0c2340 !important;
}
.ae-balloon-launcher-content {
  background: #293edd !important;
}
.ae-balloon-launcher .ae-row {
  -moz-box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25) !important;
  -webkit-box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25) !important;
}
.ae-balloon-launcher .ae-row:hover,
.ae-balloon-launcher .ae-row:focus {
  background: #d5f8ff !important;
  color: #293edd !important;
  fill: #293edd !important;
  transition: fade 0.15s ease-in-out !important;
}
.ae-btn,
a.ae-btn,
button.ae-btn,
.ae-btn-launcher .ae-row,
.ae-balloon-launcher .ae-row,
.ae-bubble-launcher .ae-row,
.ae-rectangle-launcher .ae-row {
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
}
.ae-balloon-launcher,
.ae-bubble-launcher,
.ae-btn-launcher,
.ae-rectangle-launcher,
.ae-invite,
.ae-activtarget,
.ae-panel {
  color: #0c2340 !important;
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
  z-index: 39 !important;
}
#ae-parent-container
  #ae-panel-expansion-container
  .ae-revolve-fluid-container
  .ae-revolve-header-container,
.ae-panel > header.ae-panel-header {
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
  background-color: #293edd !important;
  border-radius: 8px 8px 0 0 !important;
}
.ae-btn,
a.ae-btn,
button.ae-btn,
.ae-btn-launcher .ae-row {
  background: #293edd !important;
}
.ae-btn:hover,
.ae-btn:focus {
  background: #d5f8ff !important;
  color: #293edd !important;
  fill: #293edd !important;
}
.ae-btn,
a.ae-btn,
button.ae-btn {
  -webkit-border-radius: 9999px !important;
  -moz-border-radius: 9999px !important;
  -ms-border-radius: 9999px !important;
  border-radius: 9999px !important;
}
.ae-panel-body .ae-banner {
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
  background-color: #ffffff !important;
}
.ae-panel-body .ae-banner .ae-text {
  font-weight: 500 !important;
}
.ae-panel-body,
.ae-brand-automotive-chat-mazda #ae-chat-container {
  border-style: none !important;
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1) !important;
}
.ae-panel-body .ae-banner .ae-icon {
  padding: 20px !important;
  background: #f6f7f8 !important;
  border-width: 0px !important;
}
.ae-panel-body .ae-banner .ae-icon svg {
  color: #78819b !important;
  fill: #78819b !important;
}
#ae-parent-container
  #ae-panel-expansion-container
  .ae-revolve-fluid-container
  .ae-revolve-header-container
  .ae-option:hover,
.ae-panel > header.ae-panel-header .ae-option:hover {
  color: #d5f8ff !important;
  fill: #d5f8ff !important;
  background-color: transparent !important;
}
.ae-balloon-launcher,
.ae-bubble-launcher,
.ae-btn-launcher,
.ae-rectangle-launcher,
.ae-invite,
.ae-activtarget,
.ae-panel {
  color: #0c2340 !important;
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
}
.ae-panel > header.ae-panel-header .ae-title {
  font-weight: 500 !important;
}
.ae-balloon-launcher .ae-col-icon .ae-icon {
  width: 80% !important;
  height: 80% !important;
}
.ae-balloon-launcher .ae-col-icon .ae-icon-chat {
  width: 60% !important;
  height: 60% !important;
}
.ae-bubble-launcher-content {
  background: #293edd !important;
}
.ae-bubble-launcher .ae-row:hover,
.ae-bubble-launcher .ae-row:focus {
  background: #0c2340 !important;
}
input.ae-input[type="text"],
input.ae-input[type="radio"],
input.ae-input[type="password"],
input.ae-input[type="number"],
textarea.ae-input,
select.ae-input,
.ae-multiselect {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  border-radius: 6px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}
.ae-panel-body .ae-field .ae-field-name {
  font-weight: 500 !important;
  text-transform: uppercase !important;
  color: #78819b !important;
}
.ae-btn,
a.ae-btn,
button.ae-btn,
.ae-btn-launcher .ae-row,
.ae-balloon-launcher .ae-row,
.ae-bubble-launcher .ae-row,
.ae-rectangle-launcher .ae-row {
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
}
#ae-parent-container
  #ae-panel-expansion-container
  .ae-revolve-fluid-container
  .ae-revolve-header-container
  .ae-option:hover
  svg,
.ae-panel > header.ae-panel-header .ae-option:hover svg {
  stroke: #d5f8ff !important;
}
.ae-panel-cover .ae-icon-btn:hover {
  color: #00cfeb !important;
  fill: #00cfeb !important;
  border-color: #00cfeb !important;
}
.ae-panel-cover {
  background-color: #0c2340 !important;
}
.ae-panel-body .ae-transcript .ae-outgoing .ae-line-content {
  background-color: #78819b !important;
}
.ae-balloon-launcher.ae-size-large .ae-balloon-launcher-content {
  -webkit-border-radius: 52px !important;
  -moz-border-radius: 52px !important;
  -ms-border-radius: 52px !important;
  border-radius: 52px !important;
}
