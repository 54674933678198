@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply h-full bg-gray-25;
    scroll-behavior: auto !important;
  }

  body {
    @apply h-full;
  }

  .body-1,
  .body-2 {
    @apply font-semibold kijiji:font-normal autocan:font-normal text-lg leading-6;
  }

  .body-2 {
    @apply font-normal;
  }

  .body-3,
  .body-4 {
    @apply font-medium kijiji:font-normal autocan:font-medium text-base leading-5;
  }

  .body-4 {
    @apply font-normal;
  }

  .caption-1,
  .caption-2 {
    @apply font-medium text-sm leading-4;
  }

  .caption-2 {
    @apply font-normal;
  }

  .caption-3,
  .caption-4 {
    @apply font-medium text-xs leading-4;
  }

  .caption-4 {
    @apply font-normal text-xs leading-4;
  }

  article a {
    @apply text-primary-bold;
  }

  button:focus-visible {
    outline: none;
  }

  a:focus-visible {
    outline: none;
  }

  input[type="radio"] {
    width: 1.12em;
    height: 1.12em;
    transform: translateY(-0.075em);
    @apply appearance-none grid place-content-center border-2 border-text-light-200 rounded-full m-0;
  }

  input[type="radio"]:before {
    content: "";
    width: 0.55em;
    height: 0.55em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    @apply shadow-[inset_1em_1em] shadow-inherit rounded-full;
  }

  input[type="radio"]:checked {
    background: none;
  }

  input[type="radio"]:checked:before {
    transform: scale(1);
  }

  input[type="radio"]:focus,
  input[type="radio"]:checked {
    @apply border-current outline-none ring-0 ring-offset-0;
  }

  input[type="radio"]:checked:hover,
  input[type="radio"]:checked:focus {
    @apply border-current bg-transparent;
  }

  .form-input:focus {
    @apply border-primary-bright kijiji:border-info autocan:border-primary-bold ring-1 ring-offset-0 ring-primary-bright kijiji:ring-info autocan:ring-primary-bold shadow-none !important;
    @apply text-primary-bold kijiji:text-gray-600 autocan:text-gray-900 border;
  }
  .cleanedParagraph a {
    @apply underline text-primary-bold hover:no-underline hover:text-primary-bold-400 focus:outline-none focus:ring-1 focus:ring-primary-bold;
  }
}

.animate-delay-show {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 15px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 15px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    transform: translate3d(0, -15px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInDown {
  from {
    transform: translate3d(0, -15px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeOutUp {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(0, -25px, 0);
    opacity: 0;
  }
}

.animated {
  animation-duration: 0.55s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.55s;
  -webkit-animation-fill-mode: both;
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp 0.3s ease-in forwards;
  -webkit-animation-name: fadeInUp;
}

.fadeInDown {
  opacity: 0;
  animation-name: fadeInDown 0.3s ease-in forwards;
  -webkit-animation-name: fadeInDown;
}

.fadeOutUp {
  animation-name: fadeOutUp 0.2s ease-out;
  -webkit-animation-name: fadeOutUp;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .embla-viewport {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
  }

  .embla-viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }

  .embla-viewport.is-dragging {
    cursor: grabbing;
  }

  .embla-container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .vehicle-search-popover {
    box-shadow: 2px 2px #00cfeb, -2px 2px #00cfeb;
  }

  .pt-safe {
    padding-top: env(safe-area-inset-top);
  }

  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* Hide ActivEngage dual pop over */
#ae-launcher-container {
  display: none;
}
.ae-invite-content {
  display: none;
}

.card-overlay {
  height: 100%;
  background: linear-gradient(
    to bottom,
    #0c2340 0%,
    #0c2340 40%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
}

.card-overlay-horizontal {
  height: 100%;
  background: linear-gradient(
    to bottom,
    #0c2340 0%,
    #0c2340 30%,
    #f6f7f8 30%,
    #f6f7f8 100%
  );
}

.shadow-skeuomorphic {
  border: 1px solid #e9eaeb;

  box-shadow: 0px 1px 2px rgba(10, 13, 18, 0.05),
    inset 0px 0px 0px 1px rgba(10, 13, 18, 0.18),
    inset 0px -2px 0px rgba(10, 13, 18, 0.05);
  border-radius: 8px;
}

@import url(theme.css);
@import url(reactdatepicker.css);
@import url(slider.css);
@import url(googleautocomplete.css);
@import url(tour.css);
@import url(aftermarketpurchase.css);
@import url(consumerfinancing.css);
@import url(instantcashoffer.css);
@import url(togglebutton.css);
