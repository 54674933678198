.horizontal-slider {
  @apply m-auto mt-1 lg:mt-auto w-full;
}

.slider-thumb {
  @apply cursor-pointer border border-gray-100 bg-background-light-100 rounded-full block box-border shadow-[0px_2px_12px_rgba(0,0,0,0.05)];
}

.slider-track {
  @apply bg-gray-100;
}

.slider-track.slider-track-1 {
  @apply bg-primary-bold;
}

.slider-track-inverse {
  @apply bg-primary-bold;
}

.slider-track-inverse.slider-track-inverse-1 {
  @apply bg-gray-100;
}

.horizontal-slider .slider-track,
.horizontal-slider .slider-track-inverse {
  @apply h-2 top-1.5;
}

.horizontal-slider .slider-thumb {
  @apply w-7 h-7 -top-[3px] lg:w-5 lg:h-5 lg:top-0 outline-0;
}

.slider-thumb.slider-thumb-0 {
  @apply -ml-1;
}

.slider-thumb.active {
  @apply border-2 border-primary-bright;
}
