@media (max-width: 1280px) {
  .ico-overlay {
    background-image: linear-gradient(
      to bottom,
      #293edd 0%,
      #293edd 250px,
      #f6f7f8 250px,
      #f6f7f8 100%
    );
  }
}

@media (min-width: 768px) {
  .ico-overlay {
    background-image: linear-gradient(
      to bottom,
      #293edd 0%,
      #293edd 380px,
      #f6f7f8 380px,
      #f6f7f8 100%
    );
  }
}

@media (min-width: 1280px) {
  .ico-overlay {
    background-image: linear-gradient(
      to bottom,
      #293edd 0%,
      #293edd 410px,
      #f6f7f8 410px,
      #f6f7f8 100%
    );
  }
}
