.rightride-tour * {
  font-family: "Volte", Helvetica, Arial, sans-serif !important;
}

.rightride-tour {
  @apply p-0 pb-6;
  min-width: 340px;
  max-width: 500px;
}

/* a hack to fix intro.js tooltip not aligning properly for mobile sidebar*/
.mobile-sidebar-tooltip {
  bottom: 79px !important;
  left: -160px !important;
  top: unset !important;
  right: unset !important;
}

.rightride-tour .introjs-tooltip-header {
  @apply pt-10 px-0 mb-3;
}

.rightride-tour .introjs-skipbutton {
  @apply flex items-center justify-center;
}

.rightride-tour .introjs-tooltip-title {
  @apply m-auto p-0 my-0;
  @apply text-2xl text-center font-semibold;
}

.rightride-tour .introjs-tooltip-title .tour-right-text {
  @apply text-primary-bold-500;
}

.rightride-tour .introjs-tooltip-title .tour-payment-text {
  @apply text-primary-bright-500;
}

.rightride-tour .introjs-tooltiptext {
  @apply py-0 px-6 text-base;
}

.rightride-tour .introjs-bullets {
  @apply flex p-0 mb-4 mt-2;
}

.rightride-tour .introjs-tooltipbuttons {
  @apply flex justify-center p-0 border-t-0;
}

.rightride-tour .introjs-prevbutton {
  @apply hidden;
}

.rightride-tour .introjs-nextbutton {
  /* Override/rests introjs button default styles */
  text-shadow: 0px 0px 0 #fff;
  box-shadow: 0 0 0 0;
  @apply p-0;

  @apply box-border flex items-center justify-center rounded-full py-2.5 px-4 w-28 h-11 gap-2 transition-colors;
  @apply caption-2 text-base font-medium;
  @apply border-0;

  @apply bg-primary-bold hover:bg-primary-light-500 active:bg-primary-bold-600;
  @apply text-white hover:text-primary-bold active:text-white;
}

.introjs-helperLayer {
  @apply rounded-xl;
  box-shadow:#00CFEB 0px 0px 3px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}