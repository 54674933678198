.pac-container {
  @apply !border-0;
}
.pac-icon {
}
.pac-item {
}
.pac-item:hover {
}
.pac-item-selected {
}
.pac-item-query {
}
.pac-matched {
}
